.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.performance {
  border: 1px solid rgb(216, 216, 216);
  background: rgb(237, 236, 236);
  border-radius: 10px;
  margin: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.center {
  text-align: center;
  margin: auto;
  padding: auto;
}

.ico-clone {
  position: absolute;
  top: -6px;
  left: 5px;
}
.performance-2 {
  border: 1px solid rgb(216, 216, 216);
  background: rgb(239, 239, 239);
  border-radius: 10px;
  padding: 10px;
  /* padding-top: 5px;
  padding-bottom: 5px; */
}
.performance-2-2 {
  border: 1px solid rgb(216, 216, 216);
  background: rgb(237, 236, 236);
  border-radius: 0px 40px 0px 40px;
  padding: 10px;
  /* transform: skewX(-5deg); */
  /* padding-top: 5px;
  padding-bottom: 5px; */
}
.performance-cc {
  border: 1px solid rgb(216, 216, 216);
  background: rgb(237, 236, 236);
  border-radius: 40px 0px 0px 40px;
  padding: 10px;
  /* transform: skewX(-5deg); */
  /* padding-top: 5px;
  padding-bottom: 5px; */
}
.performance-rc {
  border: 1px solid rgb(216, 216, 216);
  background: rgb(237, 236, 236);
  border-radius: 40px 0px 40px 0px;
  padding: 10px;
  /* transform: skewX(-5deg); */
  /* padding-top: 5px;
  padding-bottom: 5px; */
}
.bg-ariztia {
  /* background: rgb(224, 224, 224); */
  background: rgb(255,59,59);
  background: -moz-linear-gradient(180deg, rgba(255,59,59,1) 0%, rgba(83,1,1,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(255,59,59,1) 0%, rgba(83,1,1,1) 100%);
  background: linear-gradient(180deg, rgba(255,59,59,1) 0%, rgba(83,1,1,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff3b3b",endColorstr="#530101",GradientType=1);
}
.distorsionado {
  padding: 20px;
  background: rgb(202, 202, 202);
  color: white;
  transform: skew(-10deg) scale(1.5);
  max-width:300px;
  height: 80vh;
  text-align:center;
}
#padre > *{
  display: block;
  transform: skew(10deg) scale(.70);
}
.invisible {
  color: transparent;
}
/* cadr edit trasnporte */
.card-tr {
  background: rgb(234, 234, 234);
  border: 1px solid white;
  border-radius: 10px;
  box-shadow: 5px 5px 5px gray;
  padding: 10px 15px;
  color: rgb(89, 89, 89);
}
.card-title {
  font-weight: 600;
}
.card-dato {
  position: relative;
  top: 3px;
}
.btn-edit {
  position: absolute;
  top: 1px;
  right: 0px;
  padding: 5px;
  background: rgb(50, 155, 27);
  color: rgb(85, 229, 69);
  border-radius: 5px 10px 5px 5px;
  margin: 3px;
  cursor: pointer;
  box-shadow: 2px 2px 2px rgb(176, 176, 176);
}
.btn-edit:hover {
  background: rgb(37, 113, 20);
  color: rgb(68, 187, 55);
}
.btn-trash {
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 5px;
  background: rgb(214, 0, 0);
  color: rgb(255, 99, 99);
  border-radius: 5px 5px 10px 5px;
  margin: 3px;
  cursor: pointer;
  box-shadow: 2px 2px 2px rgb(176, 176, 176);
}
.btn-trash:hover {
  background: rgb(176, 1, 1);
  color: rgb(214, 82, 82);
}



.modal_ {
  width: 100%;
  height: 100vh;
  background-color: #cf000037;
  position: fixed;
  top: 0px;
  z-index: 1100;
}

.modal_in {
  background: #ffffff;
  margin: auto;
  padding: auto;
}
.carga-down-file {
  cursor: pointer;
  position: relative;
  top: 5px;
  left: 5PX;
}

/* modal carga masiva */
.momodal-mo {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: #7d2727;
  overflow-y: scroll;
  display: contents;
}
.momodal-nono {
  width: 0px;
  height: 0px;
  background: #7d2727;
  display: none;
}

.banner-main {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0px;
}
.inner-banner{
  background: #ffffff;
  border-radius: 8px;
  border: 4px solid  rgb(0, 0, 0);
  padding: auto !important;
  border: auto !important;
  box-shadow: 5px 5px 5px rgba(128, 128, 128, 0.711);
  /* animation: border-color infinite 2s linear; */
}

@keyframes border-color {
  0% {
    border: 3px solid  rgb(106, 0, 255);
  }
  25% {
    border: 3px solid  rgb(255, 0, 0);
  }
  50% {
    border: 3px solid  rgb(136, 0, 255);
  }
  75% {
    border: 3px solid  rgb(255, 132, 0);
  }
  100% {
    border: 3px solid  rgb(106, 0, 255);
  }
}

.versiontxt {
  position: fixed;
  bottom: 0px;
  right: 10%;
  color: rgb(188, 188, 188);
}
.ocultacion {
  display: none !important;
}
.modeling-tablescrud {
  background-color: #ededed;
  margin: 1px;
  border-radius: 5px;
  padding-top: 5px;
  box-shadow: 5px 5px 5px rgba(166, 166, 166, 0.42);
}
.table-bordered th,
.table-bordered td,
.table-bordered thead th,
.table-bordered thead td{
  border: 1px solid #a4a4a4 !important;
}
.campo-add-data {
  position: absolute;
  background: #ffffff;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgb(172, 172, 172);
  box-shadow: 5px 5px 5px rgba(166, 166, 166, 0.42);
}
.cursor {
  cursor: pointer;
}
.modal-mntdr_on {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100vh;
  background-color: rgba(2, 0, 0, 0.4);
}
.modal-mntdr_off {
  display: none !important;
}
.modal-mntdr-cntr {
  border-radius: 10px;
  background-color: #fff;
  padding: 10px 20px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.398);
}
.modal-mntdr-iner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 50%;
  height: 30%;
  margin: auto;
}
.charki-datepicker {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 0px 10px;
  width: 100%;
}
.buttonclosebaner {
  position: relative;
  cursor: pointer;
}